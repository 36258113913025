
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import axios from "axios";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
  },
  data(): {
    Login: any;
  } {
    return {
      Login: {
        Account: "",
        Password: "",
      },
    };
  },
  methods: {
    async ToLogin() {
      axios
        .post("https://api.artinuro.com/User/login", this.Login)
        .then(res => {
          store.commit(Mutations.SET_AUTH, res.data.Token);
          localStorage.setItem("id_token", res.data.Token);
          console.log(res);
          const router = useRouter();
          Swal.fire({
            text: "登入成功!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
          location.href = "/#/dashboard";
        })
        .catch(err => {
          alert(err.response.data.error);
          console.log(err.response);
        });
    },
  },
});
